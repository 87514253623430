export const menu = [
  { 
    uid: "home-link",
    label: "Home",
    link: "/",
    class: "home-uppercase",
    external: false,
  },

  {
    uid: "podcasts-link",
    label: "Podcast",
    link: "https://www.connectpracticetrackgrow.com/",
    external: true,
  },

  {
    uid: "books-link",
    label: "Library",
    link: "",
    external: false,
    submenu: [{
      uid: "book-1",
      label: "How To Make It In The NEW Real Estate Business",
      link: "https://secure.gogoclients.com/Form2.aspx?Key=052BB9B7BBF7268BF33EB2A02EDCD605",
      external: true
    }, {
      uid: "book-2",
      label: "Coaching Big Talent",
      link: "https://secure.gogoclients.com/Form2.aspx?Key=D493E098E532D922C65D830AC7AE2E79",
      external: true
    }, {
      uid: "book-3",
      label: "The Landlord Profitability Playbook",
      link: "https://secure.gogoclients.com/Form2.aspx?Key=4E1345BA753CCC79DD6C7AD7A607036E",
      external: true
    }, {
      uid: "book-4",
      label: "Leverage Your Real Estate Legacy",
      link: "https://secure.gogoclients.com/Form2.aspx?Key=5475C9C9E27B6C19C9E58E1EFE0B4CF8",
      external: true
    }],
  },

  
  {      
    uid: "blog-link",
    label: "Blog",
    link: "https://thestrategicresource.wordpress.com/blog/",
    external: true,
    /* submenu: ["More1", "More2", "More3"], */
  },

    
  {
    uid: "chris-mcallister-link",
    label: "Chris McAllister",
    link: "/about-chris",
    external: false,
  },

  // {
  //   uid: "join-podcast-link-link",
  //   label: "Join The Podcast!",
  //   link: "http://cptg.yourpodcast.team/",
  //   class: "join-podcast",
  //   external: false,
  // },
  {
    uid: "call-chris-link",
    label: "Free Call",
    link: "/strategy-call",
    external: false,
  },
  // {
  //   uid: "get-updates-link",
  //   label: "Get Updates",
  //   link: "/get-updates",
  //   external: false,
  // },

  // {
  //   // uid: "get-updates-link",
  //   label: "More",
  //   link: "/more",
  //   external: false,
  // },

];

export const footerMenu = [
{
  uid: "footer-connect-link",
  label: "Connect",
  link: "/",
  external: false,
  submenu: [
    {
      uid: "footer-call-chris-link",
      label: "Free Call",
      link: "/strategy-call",
      external: false,
    },
    // {
    //   uid: "footer-join-podcast-link",
    //   label: "Join The Podcast",
    //   link: "http://cptg.yourpodcast.team/",
    //   external: true
    // },
    // {
    //   uid: "footer-get-updates-link",
    //   label: "Get Updates",
    //   link: "#",
    //   external: false
    // },
    {
      uid: "footer-about-chris-link",
      label: "About Chris",
      link: "/about-chris",
      external: false
    }
  ]
},
{
  uid: "footer-books-link",
  label: "Library",
  link: "/",
  external: false,
  submenu: [{
      uid: "footer-book1",
      label: "How To Make It In The NEW Real Estate Business",
      link: "https://secure.gogoclients.com/Form2.aspx?Key=052BB9B7BBF7268BF33EB2A02EDCD605",
      external: true
    }, {
      uid: "footer-book2",
      label: "Coaching Big Talent",
      link: "https://secure.gogoclients.com/Form2.aspx?Key=D493E098E532D922C65D830AC7AE2E79",
      external: true
    }, {
      uid: "footer-book3",
      label: "The Landlord Profitability Playbook",
      link: "https://secure.gogoclients.com/Form2.aspx?Key=4E1345BA753CCC79DD6C7AD7A607036E",
      external: true
    }, {
      uid: "footer-book4",
      label: "Leverage Your Real Estate Legacy",
      link: "https://secure.gogoclients.com/Form2.aspx?Key=5475C9C9E27B6C19C9E58E1EFE0B4CF8",
      external: true
    }]
},
{
  uid: "footer-content-link",
  label: "Content",
  link: "/",
  external: false,
  submenu: [
    {
      uid: "footer-content-podcast-link",
      label: "Podcast",
      link: "https://www.connectpracticetrackgrow.com/",
      external: true
    },
    {
      uid: "footer-content-blog-link",
      label: "Blog",
      link: "https://thestrategicresource.wordpress.com/blog/",
      external: true
    }
  ]
},
{
  uid: "footer-legal-link",
  label: "Legal",
  link: "/",
  external: false,
  submenu: [
    {
      uid: "footer-privacy-policy-link",
      label: "Privacy Policy",
      link: "https://thestrategicresource.wordpress.com/privacy-policy/",
      external: true,
    },
    {
      uid: "terms-conditions",
      label: "Terms & Conditions",
      link: "https://thestrategicresource.wordpress.com/terms-conditions/",
      external: true,
    }
  ]
}
];