import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";

import './Home.scss';

// images
import chrisPhoto from './../../assets/img/Chris-2021.jpeg';
import officeBackground from './../../assets/img/office-background.jpg';
import houseImg from './../../assets/img/houseImg.jpg';
import screen2Bkg from './../../assets/img/screen-2-bkg.jpg';
import podcastMic from './../../assets/img/podcast-microphone.jpg';
import blogHouse from './../../assets/img/blog-house.jpg';
import circleLogoAboutUs from './../../assets/img/circle-logo-about-us.png';
import podcast from './../../assets/img/CPTG_Logo1-01.jpg';
import podcastcroped from './../../assets/img/CPTG_Logo1-01-croped.png';

import podcastlogo from './../../assets/img/PodcastLogo.jpg';
import podcastimg from './../../assets/img/Podcast.png';
// books
import leverageLegacy from './../../assets/img/books/left face Leverage Your legacy.png';
import ultimateOwner from './../../assets/img/books/LandlordProfitabilityPlaybook.png';
import strategicResource from './../../assets/img/books/StrategicResource.png';
import coachingBigTalent from './../../assets/img/books/CoachingBigTalent.png';

// Social icons
import twitterIcon from './../../assets/img/Twitter.png';
import instagramIcon from './../../assets/img/Instagram.png';
import facebookIcon from './../../assets/img/Facebook.png';
import linkedInIcon from './../../assets/img/LinkedIn.png';

import Button from '../../components/Button/Button';
import { useState } from "react";

export default function Home(props) {

    const [bookScreenIndex, setBookScreenIndex] = useState(0);
    const [subscribeForm, setSubscribeForm] = useState({ name: '', email: '' });

    let book_screen = [
        {
            title: "Real Estate Agents & Team Leaders",
            book_cover: strategicResource,
            book_quote: "The NEW Real Estate Agent Doesn't Follow Old Rules. They Make NEW Rules.",
            location: "https://secure.gogoclients.com/Form2.aspx?Key=052BB9B7BBF7268BF33EB2A02EDCD605"
        },
        {
            title: "Real Estate Brokers & Managers",
            book_cover: coachingBigTalent,
            book_quote: "Reclaim your relevance, assert your entrepreneurial independence, and get paid for what you do.",
            location: "https://secure.gogoclients.com/Form2.aspx?Key=D493E098E532D922C65D830AC7AE2E79"
        },
        {
            title: "Property Management Professionals",
            book_cover: ultimateOwner,
            book_quote: "Don't just manage your brokerage... own it! Chris breaks down the key behaviors you need to thrive in the real estate industry!",
            location: "https://secure.gogoclients.com/Form2.aspx?Key=4E1345BA753CCC79DD6C7AD7A607036E"
        },
        {
            title: "Planning for Your Future",
            book_cover: leverageLegacy,
            book_quote: "This book is about change, choices, opportunities, and closure. Embrace your success and your bigger future today.",
            location: "https://secure.gogoclients.com/Form2.aspx?Key=5475C9C9E27B6C19C9E58E1EFE0B4CF8"
        }    
    ];

    function renderCarouselIndicator(onClickHandler, isSelected, index, label) {
        if (isSelected) {
            return <div className="indicator selected" onClick={onClickHandler}></div>;
        } else {
            return <div className="indicator" onClick={onClickHandler}></div>;
        }
    }

    return (
        <>

            <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false} showStatus={false} showArrows={false} renderIndicator={renderCarouselIndicator}>
                <div className="home-screen bkg-blue">
                    <div className="carousel-item first-section" style={{ paddingRight: 0 }}>
                        <div className="text centered-content padding-right-70 ">
                            <p className="left">"We create <b>business opportunities and strategies</b> that support and add value to the lives of <b>real estate professionals</b>."</p>
                            <span className="margin-20">- Chris McAllister<br /> Founder & CEO</span>
                            <Link to="/about-chris"><Button>Meet Chris</Button></Link>
                        </div>
                        <img class="right-photo" src={chrisPhoto} />

                    </div>
                </div>

                <div className="carousel-item ">
                    <div className="text centered-content" >
                        <p>The latest from Chris McAllister is about change, choices, and new opportunities. Surrender to your success and embrace your bigger future.</p>
                        <a href="https://roostrealestateco.com/leverage-your-real-estate-legacy/" target="_blank"><Button>Read More</Button></a>
                    </div>
                    <img className="img-contained" src={leverageLegacy} />
                </div>
                <div className="carousel-item first-section" style={{ paddingRight: 0 }}>
                    <div className="text centered-content">
                        <p>Join Chris McAllister on the podcast and uncover the unique opportunities and advantages available to you in your market.</p>

                        <a href={"https://www.connectpracticetrackgrow.com/contact"} target="_blank" className="fit-link"> <Button>Learn More</Button></a>
                    </div>
                    <img class="right-photo" src={podcastimg} />
                    {/* <img class= "right-photo display2" src={podcast}/>
                <img class= "right-photo display1" src={podcastcroped}/> */}
                </div>
            </Carousel>
            <div className="home-screen about-us">
                <div className="col-2 padding-250">
                    <div><img src={circleLogoAboutUs} /></div>
                    <div className="content margin-left-125">
                        <h4>About Us</h4>
                        <p>We are a coaching and leadership resource for real estate sales professionals, brokers and managers, team leaders, and property managers.</p>
                    </div>
                </div>
            </div>
            <div className="home-screen bkg-blue">
                <div className="carousel-item" style={{ paddingRight: 0 }}>

                    <div className="content white estate-podcast ">
                        <h4>Schedule a one-on-one personal coaching call with Chris McAllister.</h4>
                        <p>Get clear on what to prioritize right now to achieve your next breakthrough in revenue and success.</p>
                        <Link to="strategy-call" className="fit-link"><Button>Let's Chat</Button></Link>
                    </div>
                    <img class="right-photo" src={officeBackground} />
                </div>
            </div>
            <div className="home-screen screen-books padding-250" style={{ backgroundColor: "#df7e36" }}>
                <div className="content white">
                    <h4>The Library</h4>
                    <p>The Strategic Real Estate Resource FREE library will keep you and your career moving in the right direction.</p>
                    <div className="toggle-section-wrapper">
                        <div className="toggleBooks">
                            {book_screen.map((el, index) => {
                                return <div key={index} className={`book-item` + (index == bookScreenIndex ? ` selected` : ``)} onClick={() => setBookScreenIndex(index)}>{el.title}</div>
                            })}
                        </div>
                        <div className="toggleContent">
                            <div className="text">
                                {book_screen[bookScreenIndex].book_quote}
                                <a href={book_screen[bookScreenIndex].location} target="_blank"><Button>Read More</Button></a>
                            </div>
                            <img src={book_screen[bookScreenIndex].book_cover} alt={book_screen[bookScreenIndex].book_cover} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-screen bkg-blue"  >
                <div className="carousel-item mic-container" style={{ paddingRight: 0 }}>
                    <div className="content white estate-podcast padding-right-70 ">
                        <h4>The Connect Practice Track & Grow Podcast</h4>
                        <p><i>
                            Connect Practice Track & Grow is the real estate industry’s first podcast devoted to how practicing real estate professionals can collaborate with their licensed real estate brokers to achieve their shared goals and aspirations using The Eight Collaboration Accelerators.
                        </i></p>
                        <a href={"https://www.connectpracticetrackgrow.com/"} target="_blank" className="fit-link"> <Button>Listen In</Button></a>
                    </div>
                    <img class="right-photo podcast-image" src={podcastimg} />
                </div>
            </div>
            <div className="home-screen bkg-white strategic-podcast quick-reads" style={{ marginLeft: "0px" }}>
                <div className="col-2 padding-left-custom" style={{ paddingLeft: "0px" }}>
                    <img class="mic-photo right-photo" src={blogHouse} />
                    <div className="content margin-left-125">
                        <h4>The Strategic Real Estate Resource Blog</h4>
                        <p><i>Get our latest thinking on the state of the real estate industry.</i>
                        </p>
                        <a href={"https://thestrategicresource.wordpress.com/blog/"} target="_blank" className="fit-link"><Button >Read Up</Button></a>
                    </div>
                </div>
            </div>
            {/* <div className="subscribe-screen">
            <h4>Get our Monthly Newsletter</h4>
            <p>
                Subscribe to our monthly newsletter for content available <br/>
                to The Strategic Real Estate Resource subscribers only.
            </p>
            <div className="form-subscribe">
                <input type="text" placeholder="Name (Optional)" value={subscribeForm.data} onChange={(e) => setSubscribeForm({...subscribeForm, name: e.target.value})}/>
                <input type="text" placeholder="Email" value={subscribeForm.email} onChange={(e) => setSubscribeForm({...subscribeForm, email: e.target.value})}/>
            </div>
            <Button>
                Subscribe
            </Button>
        </div> */}
            <div className="social-screen">
                <div className="top-part">
                    <h4>Follow us on social media!</h4>


                </div>
                <div className="bottom-part">
                    <a href="https://twitter.com/TSRRealEstate" target="_blank">
                        <img src={twitterIcon} class="twitter-img" />
                    </a>
                    <a href="https://www.facebook.com/thestrategicresource" target="_blank">
                        <img src={facebookIcon} />
                    </a>
                    <a href="https://www.linkedin.com/company/80951994/admin/" target="_blank">
                        <img src={linkedInIcon} />
                    </a>
                </div>
            </div>
        </>);
}