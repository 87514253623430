import './StrategyCall.scss';
import deskBackground from './../../assets/img/side-desk-devices.jpg';
import Button from '../../components/Button/Button';
import { useEffect } from 'react';

export default function StrategyCall() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="strategy-call-page">
            <div className="header bkg-blue">
                <h1>A Clearer Path To Business And Personal Growth Is One Personalized Strategy Call Away.</h1>
            </div>
            <div className="content">
                <div className="inner-content">
                    <h4>Get clear on what to prioritize right now to achieve your next breakthrough
                        in revenue and success.
                    </h4>
                    <p>Many agents just like you have used our Personalized Strategy Calls to
                        help them get clear on what to prioritize right now to ensure their
                        personal and professional growth in the years ahead.
                    </p>
                    <h5>During this free 45-minute personalized Strategy Session you'll:</h5>
                    <ul className='list-div'>
                        <div >
                            <li>Make your invisible opportunites visible.</li>
                            <li>Identify challenges.</li>
                            <li>Get clear on your strengths and unique advantages.</li>
                        </div>

                        <div >
                            <li>Be certain that you are focused on the right stuff.</li>
                            <li>Create an action plan for the next 90 days.</li>
                            <li>Receive 45 minutes of undivided attention focused on YOU.</li>
                        </div>

                    </ul>
                    <p>New agents, veteran agents, team leaders, managers, and broker-owners welcome.
                    </p>
                    <div className='end-button'>
                        <a href="https://calendly.com/chrismcallister/45-minute-discovery-call-with-chris-mcallister-the-strategic-real-estate-resource" target="_blank">
                            <Button>Schedule Here</Button>
                        </a>
                    </div>
                </div>
                {/* <img src={deskBackground} /> */}
            </div>
        </div>
    )
}